const DATA_ROADMAP = [
	[ 'Name', 'Manager' ],
	[ 'Scratch', '' ],
	[ 'Python', 'Scratch' ],
	[ 'Py-Games', 'Python' ],
	[ 'C#', 'Py-Games' ],
	[ 'Unity Games', 'C#' ],
	[ 'AP CS P', 'Python' ],
	[ 'Java', 'AP CS P' ],
	[ 'AP CS A', 'Java' ],
	[ 'Web', 'Python' ],
	[ 'Javascript', 'Web' ],
	[ 'Mobile', 'Javascript' ],
	[ 'Bootcamp', 'Javascript' ],
	[ 'Competition 101', 'Python' ],
	[ 'USACO Bronze', 'Competition 101' ],
	[ 'USACO Silver', 'USACO Bronze' ],
	[ 'USACO Gold', 'USACO Silver' ]
];

export default DATA_ROADMAP;

/*
{
			v: 'Scratch',
			f: 'Scratch <i class="text-success fas fa-check" />'
		}
		*/
