import React, { Fragment, useContext } from 'react';
import { auth } from '../../utils/DbService';
import { UserContext } from '../../context/Contexts';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

// const LANGS = {
// 	en: { img: 'united_kingdom', txt: 'English', code: 'en' },
// 	kr: { img: 'korea_south', txt: '한국어', code: 'kr' },
// 	cn: { img: 'china', txt: '中文', code: 'cn' }
// };

export default function TopNav() {
	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
			<Container>
				<LinkContainer to="/">
					<Navbar.Brand>
						<img src="https://i.imgur.com/IuJpm6D.png" height="40" className="d-inline-block align-top" alt="logo" />
					</Navbar.Brand>
				</LinkContainer>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					{!auth.loggedIn() && <SubMenuGuest />}
					{auth.loggedIn() && auth.getParent() && <SubMenuParent />}
					{auth.loggedIn() && !auth.getParent() && <SubMenuUser />}
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

function SubMenuGuest() {
	return (
		<Fragment>
			<Nav className="mr-auto" />
			<Nav />
		</Fragment>
	);
}

function SubMenuParent() {
	let { username, confirmLogin } = useContext(UserContext);
	const logout = () => {
		auth.logout();
		confirmLogin(undefined);
	};
	return (
		<Fragment>
			<Nav className="mr-auto">Parent Portal</Nav>
			<Nav className="mr-2 ml-auto">
				<NavDropdown title={username} id="basic-nav-dropdown">
					<LinkContainer to="/member/profile">
						<NavDropdown.Item>Profile</NavDropdown.Item>
					</LinkContainer>
					<NavDropdown.Divider />
					<NavDropdown.Item onClick={logout}>Log Out</NavDropdown.Item>
				</NavDropdown>
			</Nav>
		</Fragment>
	);
}

function SubMenuUser() {
	let { username, confirmLogin } = useContext(UserContext);
	const logout = () => {
		auth.logout();
		confirmLogin(undefined);
	};
	return (
		<Fragment>
			<Nav className="mr-auto">
				{auth.isAdmin() && (
					<LinkContainer to="/member/dashboard">
						<Nav.Link>Dashboard</Nav.Link>
					</LinkContainer>
				)}
				<LinkContainer to="/member/my-courses">
					<Nav.Link>Courses</Nav.Link>
				</LinkContainer>
			</Nav>
			<Nav className="mr-2 ml-auto">
				{auth.isAdmin() && (
					<NavDropdown title="⚙️Admin" id="nav-dropdown-admin">
						<LinkContainer to="/admin/index">
							<NavDropdown.Item>Admin</NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to="/admin/students">
							<NavDropdown.Item>Students</NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to="/admin/courses">
							<NavDropdown.Item>Courses</NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to="/admin/quiz-tags">
							<NavDropdown.Item>Quizbank</NavDropdown.Item>
						</LinkContainer>
					</NavDropdown>
				)}
				<NavDropdown title={username} id="basic-nav-dropdown">
					<LinkContainer to="/member/profile">
						<NavDropdown.Item>Profile</NavDropdown.Item>
					</LinkContainer>
					<NavDropdown.Divider />
					<NavDropdown.Item onClick={logout}>Log Out</NavDropdown.Item>
				</NavDropdown>
			</Nav>
		</Fragment>
	);
}
