import React, { useEffect, useState } from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router-dom';
import { db } from '../../../../utils/DbService';
import { FullScreenLoading } from '../../../../components2/loading/Loading';
import Reports from './reports';
import AdminStudentDashboard from './dashboard/index';

export default function AdminStudent() {
	const [ student, setStudent ] = useState(undefined);
	const { studentId } = useParams();

	useEffect(
		() => {
			db.fetch(`admin/users/${studentId}`).then(setStudent);
		},
		[ studentId ]
	);

	if (!student) return <FullScreenLoading txt="Loading student info..." />;

	return (
		<Container className="my-3">
			<Breadcrumb>
				<LinkContainer to="/admin/index">
					<Breadcrumb.Item>Admin Home</Breadcrumb.Item>
				</LinkContainer>
				<LinkContainer to="/admin/students">
					<Breadcrumb.Item>Students</Breadcrumb.Item>
				</LinkContainer>
				<Breadcrumb.Item active>
					{student.name} ({student.username})
				</Breadcrumb.Item>
			</Breadcrumb>

			<AdminStudentDashboard student={student} />
			<Reports student={student} />
		</Container>
	);
}
