import { subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import Chart from 'react-google-charts';
import { FullScreenLoading } from '../../../../../components2/loading/Loading';
import { db } from '../../../../../utils/DbService';
import DashboardTimeline from '../../../../member/dashboard/dashboard-timeline';
import RoadMap from '../../../../member/dashboard/roadmap';
import AlgoIndexEdit from './algo-index-edit';

export default function AdminStudentDashboard({ student }) {
	let [ showAlgoIndexEditor, setShowAlgoIndexEditor ] = useState(false);
	const [ indexes, setIndexes ] = useState([]);

	useEffect(
		() => {
			db.fetch(`admin/algoindex?user_id=${student.id}`).then((data) => {
				const indexes = new Array(6).fill(0).map((_, i) => {
					const offsetDate = subMonths(new Date(), i);
					const foundData = data.find((d) => offsetDate.getFullYear() === d.yyyy && offsetDate.getMonth() + 1 === d.mm);
					return {
						yyyy: offsetDate.getFullYear(),
						mm: offsetDate.getMonth() + 1,
						level: foundData ? foundData.level : 0
					};
				});
				setIndexes(indexes);
			});
		},
		[ student ]
	);

	if (!student) return <FullScreenLoading txt="Loading student data..." />;

	const chartData = [
		[ '', student.name, 'USACO Bronze', 'USACO Silver' ],
		...indexes.map((v) => [ new Date(v.yyyy, v.mm), v.level, 30, 50 ])
	];

	return (
		<Container className="mt-3 mb-3">
			<h1 className="text-primary">{student.name}</h1>

			<Row>
				<Col>
					<RoadMap student={student} />

					<Card>
						<Card.Header className="d-flex justify-content-between">
							<span>BTree Algo-Index</span>
							<Button size="sm" onClick={() => setShowAlgoIndexEditor(true)}>
								Edit
							</Button>
						</Card.Header>
						<Card.Body className="d-flex align-items-center justify-content-center">
							<Chart
								width={'100%'}
								height={'400px'}
								chartType="Line"
								loader={<div>Loading Chart</div>}
								data={chartData}
								rootProps={{ 'data-testid': '1' }}
								options={{
									legend: {
										position: 'bottom'
									}
								}}
								style={{
									fontSize: '0.9em'
								}}
							/>
						</Card.Body>
					</Card>
					<AlgoIndexEdit
						show={showAlgoIndexEditor}
						onClose={() => setShowAlgoIndexEditor(false)}
						student={student}
						indexes={indexes}
						setIndexes={setIndexes}
					/>
				</Col>
				<Col>
					<h3 className="mt-3">Timeline</h3>
					<DashboardTimeline student={student} />
				</Col>
			</Row>
		</Container>
	);
}
