import decode from 'jwt-decode';

class DbService {
	constructor() {
		this.domain = process.env.REACT_APP_API_URL;
		this.org_id = process.env.REACT_APP_ORG_ID;
		this.fetch = this.fetch.bind(this);
	}

	fetch(url, options) {
		url = this.domain + '/' + url;

		const headers = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		};

		headers['Authorization'] = 'Bearer ' + localStorage.getItem('id_token');

		return fetch(url, {
			headers,
			...options
		}).then(this._checkStatus);
	}

	_checkStatus(response) {
		if (response.status >= 200 && response.status <= 300) {
			return response.json();
		} else if (response.status === 401) {
			localStorage.removeItem('id_token');
			localStorage.removeItem('username');
			localStorage.removeItem('usertype');
			localStorage.removeItem('is_parent');
			window.location.reload();
			console.log('logging out', response);
		} else {
			let error = new Error();
			error.response = response;
			// error.setFieldErrors = (setFieldError) => {
			// 	error.response.json().then((errBody) => {
			// 		if (errBody && errBody.msgs) {
			// 			errBody.msgs.forEach((m) => setFieldError(m.field, m.message));
			// 		}
			// 	});
			// };
			throw error;
		}
	}

	nullToBlank(obj) {
		let obj2 = { ...obj };
		Object.keys(obj2).forEach((k) => {
			if (obj2[k] === null) obj2[k] = '';
		});

		return obj2;
	}
}

class AuthService extends DbService {
	constructor() {
		super();
		// this.fetch = this.fetch.bind(this);
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.loggedIn = this.loggedIn.bind(this);
		this.getUsername = this.getUsername.bind(this);
		this.rand = parseInt(Math.random() * 100);
		this.getUsertype = this.getUsertype.bind(this);
		this.isAdmin = this.isAdmin.bind(this);
	}

	async login(username, password, isParent = false) {
		let url = isParent ? `parent/users/signin` : 'users/signin';
		let res = await this.fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				username,
				password,
				org_id: this.org_id
			})
		});

		if (res.status === 200) {
			let json = await res.json();
			localStorage.setItem('id_token', json.token.token);
			localStorage.setItem('username', username);
			localStorage.setItem('usertype', json.usertype);
			localStorage.setItem('uid', json.uid);
			localStorage.setItem('is_parent', isParent);
			return Promise.resolve();
		} else if (res.status === 401) {
			return Promise.reject('Invalid Login');
		} else {
			return Promise.reject('Unknown system error.');
		}
	}

	logout() {
		localStorage.removeItem('id_token');
		localStorage.removeItem('username');
		localStorage.removeItem('usertype');
		localStorage.removeItem('uid');
		localStorage.removeItem('is_parent');
	}

	getUsername() {
		if (this.loggedIn()) return localStorage.getItem('username');
	}
	getUid() {
		if (this.loggedIn()) return parseInt(localStorage.getItem('uid'), 10);
	}
	getUsertype() {
		return localStorage.getItem('usertype');
	}
	getParent() {
		return localStorage.getItem('is_parent') === "true";
	}

	loggedIn() {
		let token = localStorage.getItem('id_token');
		let uid = localStorage.getItem('uid');
		if (token === null || uid === null) return false;

		try {
			const decoded = decode(token);
			return decoded.exp > Date.now() / 1000;
		} catch (err) {
			return false;
		}
	}

	isAdmin() {
		return this.getUsertype() === 'staff' || this.getUsertype() === 'instructor';
	}
	isStaff() {
		return this.getUsertype() === 'staff';
	}

	_checkStatus(response) {
		if (response.status >= 200 && response.status <= 300) {
			return response;
		} else {
			let error = new Error(response.statusText);
			error.response = response;
			throw error;
		}
	}
}

const db = new DbService();
const auth = new AuthService();
export { db, auth };
