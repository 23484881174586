import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, ButtonGroup, Card, Container, ProgressBar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { chapterModel } from '../../../model/models';
import { auth, db } from '../../../utils/DbService';
import { MySortableContainer } from '../../../components2/sortable-container/sortable-container';
import { FullScreenLoading } from '../../../components2/loading/Loading';

const ShadowCard = styled(Card)`
  cursor: pointer;
  margin: 5px;

  &:hover {
    box-shadow: 1px 1px 3px darkgray;
  }
`;

export default function MyCourseView() {
	const { pcode } = useParams();
	const { pathname } = useLocation();

	let [ course, setCourse ] = useState(undefined);
	let [ editmode, setEditmode ] = useState(false);

	const onSortEnd = ({ newIndex, oldIndex }) => {
		setCourse((prev) => {
			let cur = [ ...prev.chapters ];
			let elem = cur[oldIndex];
			cur.splice(oldIndex, 1);
			cur.splice(newIndex, 0, elem);

			for (let i = Math.min(oldIndex, newIndex); i <= Math.max(oldIndex, newIndex); i++) {
				const ch = cur[i];
				db.fetch(`admin/courses/chapters/${ch.id}`, {
					method: 'PUT',
					body: JSON.stringify({ weight: i })
				});
			}
			return {...prev, chapters: cur};
		});
	}

	const addChapter = async () => {
		let name = window.prompt('Enter name of the new chapter');
		if (name) {
			let newChapter = {
				...chapterModel({}).data,
				course_id: course.id,
				type: 'markdown',
				name,
				weight: course.chapters.length
			};
			let docRef = await db.fetch(`admin/courses/chapters`, {
				method: 'POST',
				body: JSON.stringify(newChapter)
			});
			newChapter.id = docRef.id;
			setCourse({ ...course, chapters: [ ...course.chapters, newChapter ] });
		}
	};
	// const deleteChapter = async (id) => {
	// 	if (!window.confirm('Are you sure?')) return;
	// 	await db.fetch(`admin/courses/chapters/${id}`, {
	// 		method: 'DELETE'
	// 	});
	// 	const chapters = course.chapters.filter((c) => c.id !== id);
	// 	setCourse({ ...course, chapters });
	// };

	useEffect(
		() => {
			db
				.fetch(`courses/code/${pcode}?withChapters=true`)
				.then((data) => {
					// data.chapters.forEach((el) => (el.progress = 0));
					data.chapters.forEach((el) => (el.progress = parseInt(Math.random() * 100, 10)));
					return data;
				})
				.then(setCourse);
		},
		[ pcode ]
	);

	if (!course) return <FullScreenLoading txt="Loading course materials..." />;

	return (
		<Container className="my-3">
			<Breadcrumb>
				<LinkContainer to="/member">
					<Breadcrumb.Item>Member Home</Breadcrumb.Item>
				</LinkContainer>
				<LinkContainer to="/member/my-courses">
					<Breadcrumb.Item>Courses</Breadcrumb.Item>
				</LinkContainer>
				<Breadcrumb.Item active>{pcode}</Breadcrumb.Item>
			</Breadcrumb>

			<h2 className="text-primary mb-3 text-uppercase d-flex justify-content-between">
				<span>{pcode} chapters</span>
				{auth.isAdmin() && (
					<ButtonGroup>
						<Button size="sm" variant="outline-primary" onClick={() => setEditmode(!editmode)}>
							<i className="fas fa-sort-amount-down" />
						</Button>
						<Button size="sm" variant="outline-primary" onClick={addChapter}>
							<i className="fas fa-plus" />
						</Button>
					</ButtonGroup>
				)}
			</h2>

			{editmode && (
				<MySortableContainer data={course.chapters} onSortEnd={onSortEnd} axis="xy">
					{(ch) => (
						<Card className="m-2" key={ch.id}>
							<Card.Body>
								<small> {ch.name}</small>
							</Card.Body>
						</Card>
					)}
				</MySortableContainer>
			)}

			{!editmode && (
				<div className="d-flex flex-wrap">
					{course.chapters.map((ch, i) => {
						const isHeader = ch.name.startsWith('# ');
						return (
							<LinkContainer to={`${pathname}/${ch.id}`} key={ch.id}>
								<ShadowCard border={ch.progress === 100 ? 'success' : ''} className="text-center">
									<Card.Body>
										<Card.Text>
											{isHeader ? (
												<strong>{ch.name.substring(2)}</strong>
											) : (
												<small>
													{i + 1}. {ch.name}
												</small>
											)}
										</Card.Text>

										<ProgressBar
											now={ch.progress}
											animated={ch.progress < 100}
											variant={ch.progress === 100 ? 'success' : 'info'}
											style={{
												visibility: isHeader ? 'hidden' : 'visible'
											}}
										/>
									</Card.Body>
								</ShadowCard>
							</LinkContainer>
						);
					})}
				</div>
			)}
		</Container>
	);
}
