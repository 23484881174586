import { format } from 'date-fns';
import { db } from '../utils/DbService';

const crud = ({ api_uri, setEntries }) => ({
	createEntry: (data, setFieldError) =>
		db
			.fetch(`${api_uri}`, {
				method: 'POST',
				body: JSON.stringify(data)
			})
			.then((json) => {
				setEntries((entries) => [ ...entries, { ...data, id: json.id } ]);
			})
			.catch((err) => {
				console.error(err);
				err.setFieldErrors(setFieldError);
			})
			.finally(() => Promise.resolve()),
	updateEntry: (data, setFieldError) =>
		db
			.fetch(`${api_uri}/${data.id}`, {
				method: 'PUT',
				body: JSON.stringify(data)
			})
			.then(() => {
				setEntries((entries) => entries.map((en) => (en.id === data.id ? { ...en, ...data } : en)));
			})
			.catch((err) => {
				err.setFieldErrors(setFieldError);
			})
			.finally(() => Promise.resolve()),
	deleteEntry: (id) => {
		if (!window.confirm('Are you sure you want to delete this entry?')) return;
		setEntries((entries) => entries.filter((en) => en.id !== id));
		return db.fetch(`${api_uri}/${id}`, { method: 'DELETE' });
	}
});

const classModelAdmin = ({ setEntries }) => ({
	fields: [
		'id',
		'course_id',
		'room',
		'day',
		'time',
		'duration',
		'grade',
		'is_private',
		'is_new',
		'is_tentative',
		'is_temporary'
	],
	data: {
		id: -1,
		course_id: 17,
		room: 1,
		day: 0,
		time: 2,
		duration: 120,
		grade: 'elementary',
		is_private: false,
		is_new: false,
		is_tentative: false,
		is_temporary: false
	},
	selectFields: {
		day: [
			{ key: 0, value: 'M' },
			{ key: 1, value: 'TU' },
			{ key: 2, value: 'W' },
			{ key: 3, value: 'Th' },
			{ key: 4, value: 'F' },
			{ key: 5, value: 'Sa' },
			{ key: 6, value: 'Su' }
		],
		grade: [
			{ key: 'elementary', value: 'elementary' },
			{ key: 'middle', value: 'middle' },
			{ key: 'high', value: 'high' },
			{ key: 'adults', value: 'adults' }
		]
	},
	booleanFields: [ 'is_private', 'is_new', 'is_tentative', 'is_temporary' ],
	...crud({ setEntries, api_uri: 'admin/classes' })
});

const courseModelAdmin = ({ setEntries }) => ({
	fields: [ 'id', 'code', 'name', 'icon', 'desc', 'active' ],
	data: {
		id: -1,
		code: '',
		name: '',
		icon: '',
		desc: '',
		active: 1
	},
	longFields: [ 'desc' ],
	...crud({ setEntries, api_uri: 'courses' })
});
const courseModelStaff = ({ setEntries }) => {
	let ret = courseModelAdmin({ setEntries });
	ret.fields.push('base_price');
	ret.data.base_price = 40;
	ret = { ...ret, ...crud({ setEntries, api_uri: 'admin/course' }) };
	return ret;
};

function courseCodeToColor(code) {
	switch (code.split('-')[0]) {
		case 'java':
			return 'orange';
		case 'python':
			return 'yellow';
		case 'scratch':
			return 'green';
		default:
			return 'blue';
	}
}

let userModelAdmin = ({ setEntries }) => ({
	fields: [
		'id',
		'username',
		'email',
		'name',
		'phone',
		'guardian_email',
		'guardian_phone',
		'usertype',
		'school',
		'graduation_year',
		'is_active',
		'memo'
	],
	data: {
		id: -1,
		username: '',
		email: '',
		name: '',
		phone: '',
		guardian_email: '',
		guardian_phone: '',
		usertype: 'student',
		school: '',
		graduation_year: '',
		is_active: 1,
		memo: ''
	},
	selectFields: {
		usertype: [
			{ key: 'student', value: 'student' },
			{ key: 'staff', value: 'staff' },
			{ key: 'instructor', value: 'instructor' }
		]
	},
	longFields: [ 'memo' ],
	booleanFields: [ 'is_active' ],
	...crud({ setEntries, api_uri: 'admin/users' })
});
let userModelStaff = ({ setEntries }) => {
	let ret = userModelAdmin({ setEntries });
	ret = { ...ret, ...crud({ setEntries, api_uri: 'staff/users' }) };
	return ret;
};

let chapterModel = ({ setEntries }) => ({
	fields: [ 'course_id', 'name', 'weight', 'is_subchapter', 'box1', 'box2', 'box3', 'box4' ],
	data: {
		course_id: -1,
		name: 'new chapter',
		weight: 100,
		is_subchapter: 0,
		box1: 'markdown',
		box2: '',
		box3: '',
		box4: ''
	},
	booleanFields: [ 'is_subchapter' ],
	...crud({ setEntries, api_uri: 'chapters' })
});

const classRecordModelAdmin = ({ setEntries, courses = [] }) => ({
	fields: [
		'date',
		'id',
		'user_id',
		'course_id',
		'is_present',
		'is_private',
		'minutes',
		'did_homework',
		'quiz_score',
		'next_homework',
		'notes'
	],
	data: {
		date: format(new Date(), 'yyyy-MM-dd'),
		id: -1,
		user_id: -1,
		course_id: 17,
		is_present: 0,
		is_private: 0,
		minutes: 120,
		did_homework: 0,
		quiz_score: 0,
		next_homework: '',
		notes: ''
	},
	selectFields: {
		did_homework: [
			{ key: 2, value: 'Good' },
			{ key: 1, value: 'Satisfactory' },
			{ key: 0, value: 'No Homework' },
			{ key: -1, value: 'Unsatisfactory' }
		],
		quiz_score: [
			{ key: 2, value: 'Good' },
			{ key: 1, value: 'Satisfactory' },
			{ key: 0, value: 'No Quiz' },
			{ key: -1, value: 'Unsatisfactory' }
		],
		course_id: courses.map((c) => ({
			key: c.id,
			value: c.code
		}))
	},
	booleanFields: [ 'is_present', 'is_private', 'is_new', 'is_tentative', 'is_temporary' ],
	longFields: [ 'notes', 'next_homework' ],
	...crud({ api_uri: 'admin/classrecords', setEntries })
});

const classRecordModelStaff = ({ setEntries, courses }) => {
	let ret = classRecordModelAdmin({ setEntries, courses });
	ret.fields = [ ...ret.fields, 'base_price', 'discount' ];
	ret.data = { ...ret.data, base_price: 0, discount: 0 };
	return ret;
};

// const classRecordModelStaff = discount

const billingRecordModelStaff = ({ setEntries }) => ({
	fields: [ 'date', 'id', 'user_id', 'amt', 'payment_type', 'note', 'type' ],
	data: {
		date: format(new Date(), 'yyyy-MM-dd'),
		id: -1,
		user_id: -1,
		amt: 0,
		payment_type: 'check',
		note: '',
		type: 'payment'
	},
	selectFields: {
		payment_type: [ 'check', 'cash', 'card', 'etc' ].map((t) => ({ key: t, value: t })),
		type: [ 'balance', 'adjustment', 'payment', 'invoice' ].map((t) => ({ key: t, value: t }))
	},
	...crud({ api_uri: 'staff/billingrecords', setEntries })
});

const userNoteModelAdmin = ({ setEntries }) => ({
	fields: [ 'id', 'user_id', 'date', 'note', 'sticky' ],
	data: {
		id: -1,
		user_id: -1,
		date: format(new Date(), 'yyyy-MM-dd'),
		note: '',
		sticky: 0
	},
	booleanFields: [ 'sticky' ],
	longFields: [ 'note' ],
	...crud({ api_uri: 'admin/usernotes', setEntries })
});

let quizTagModel = {
	id: -1,
	name: '',
	desc: '',
	level: 1
};

export {
	courseModelAdmin,
	courseModelStaff,
	courseCodeToColor,
	classModelAdmin,
	userModelAdmin,
	userModelStaff,
	chapterModel,
	classRecordModelAdmin,
	classRecordModelStaff,
	billingRecordModelStaff,
	userNoteModelAdmin,
	quizTagModel
};
