import toObject from 'form-to-object';
import React, { useContext, useRef, useState } from 'react';
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../context/Contexts';
import { auth } from '../../../utils/DbService';
import Toggle from 'react-toggle';

export default function Login() {
	const location = useLocation();
	const { confirmLogin } = useContext(UserContext);
	const formRef = useRef(null);

	let [ submitting, setSubmitting ] = useState(false);
	let [ loginFailed, setLoginFailed ] = useState(false);
	let [ parent, setParent ] = useState(false);

	const doLogin = async (event) => {
		event.preventDefault();
		setSubmitting(true);
		setLoginFailed(false);
		let data = toObject(event.target);

		try {
			await auth.login(data.username, data.password, parent);
			confirmLogin(data.username, new URLSearchParams(location.search).get('from'));
		} catch (err) {
			setLoginFailed(true);
			setSubmitting(false);
		}
	};

	return (
		<Container className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
			<Card>
				<Card.Header>
					<strong>{parent ? 'Parent' : 'Member'} Login</strong>
				</Card.Header>
				<Card.Body>
					{loginFailed && <Alert variant="danger">Invalid password</Alert>}
					<Form onSubmit={doLogin} ref={formRef}>
						<Form.Group>
							<Form.Label>Username</Form.Label>
							<Form.Control type="text" name="username" required />
						</Form.Group>

						<Form.Group>
							<Form.Label>Password</Form.Label>
							<Form.Control type="password" name="password" />
						</Form.Group>

						<Form.Group>
							<Form.Label>Parent</Form.Label>
							<div>
							<Toggle id="active-toggle" defaultChecked={parent} onChange={() => setParent((v) => !v)}  />
							</div>
						</Form.Group>

						<Button variant="primary" type="submit" disabled={submitting}>
							{submitting ? `Logging in...` : `Login`}
						</Button>
					</Form>
				</Card.Body>
			</Card>
		</Container>
	);
}
