import React, { useEffect, useState } from 'react';
import { Card, Container, ListGroup, Row, Col } from 'react-bootstrap';
import { db } from '../../../utils/DbService';

export default function Inquiry() {
	const [ list, setList ] = useState([]);

	useEffect(() => {
		// Axios.get(`${process.env.REACT_APP_API_URL}/admin/email`).then(setList);
		db.fetch(`admin/email`).then(setList);
	}, []);

	return (
		<Container className="my-3">
			<h3 className="text-primary my-3">Inquiries / Signups</h3>
			<Row>
				{list.map((record) => (
					<Col key={record.id} md="4">
						<Card className="mb-3">
							<Card.Header>{record.name}</Card.Header>
							<ListGroup variant="fluid">
								{'type,course,times,grade,school,exp,contact_method,email,phone,message,created_at'
									.split(',')
									.filter((col) => record[col] !== null)
									.map((col) => (
										<ListGroup.Item key={col}>
											<strong className="text-info mr-3">{col}</strong>{' '}
											<span style={{ whiteSpace: 'pre-wrap' }}>{record[col]}</span>
										</ListGroup.Item>
									))}
							</ListGroup>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
}
