import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MemberDashboard from './dashboard/member-dashboard';
import MarkdownViewer from './my-courses/chapter/markdown';
import MyCoursesList from './my-courses/list';
import MyCourseView from './my-courses/view';
import MemberProfile from './profile/profile-index';


export default function MemberIndex() {
	return (
		<Switch>
			<Route path="/member/my-courses/:pcode/:chapterId" component={MarkdownViewer} />
			<Route path="/member/my-courses/:pcode" component={MyCourseView} />
			<Route path="/member/my-courses" component={MyCoursesList} />
			<Route path="/member/profile" component={MemberProfile} />
			<Route path="/member/dashboard" component={MemberDashboard} />
			<Route path="/member" component={MemberDashboard} />
		</Switch>
	);
}
