import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import styled from 'styled-components';
import Chart from 'react-google-charts';
import DATA_ROADMAP from '../../../data/btree/roadmap';
import { db, auth } from '../../../utils/DbService';
import { FullScreenLoading } from '../../../components2/loading/Loading';

const OrgChartDiv = styled.div`
	table {
		border-collapse: unset;
	}

	table td.google-visualization-orgchart-linenode {
		border-color: rgba(10, 10, 10, 0.2);
	}
`;

export default function RoadMap({ student }) {
	const [ ar, setAr ] = useState(undefined);
	const [ isEventSet, setIsEventSet ] = useState(false);

	useEffect(() => {
		if (!student) {
			db.fetch(`roadmapcompletion/me`).then((rec) => {
				if (rec.values) setAr(JSON.parse(rec.values));
				else setAr(new Array(16).fill(0));
			});
		} else if (auth.isAdmin()) {
			db.fetch(`admin/roadmapcompletion?user_id=${student.id}`).then((rec) => {
				if (rec.values) setAr(JSON.parse(rec.values));
				else setAr(new Array(16).fill(0));
			});
		}
	}, [student]);

	const toggle = (i) => {
		if (!auth.isAdmin()) return;
		setAr((ar) => {
			let ar2 = [ ...ar ];
			ar2[i] = (ar2[i] + 25) % 125;
			return ar2;
		});
	};

	const save = () => {
		if (!auth.isAdmin()) return;
		db.fetch(`admin/roadmapcompletion`, {
			method: 'POST',
			body: JSON.stringify({
				user_id: student.id,
				values: JSON.stringify(ar)
			})
		});
	};

	if (!ar) return <FullScreenLoading txt="Loading data..." />;

	console.log(ar);
	const data_roadmap = [ ...DATA_ROADMAP ].map((v, i) => {
		if (i === 0) return v;
		return [
			{
				v: v[0],
				// f: `${v[0]} <br>${new Array(ar[i-1]).fill(`<i class="fas fa-check text-success" />`).join('')}`
				f: `${v[0]}<br><div class="progress">
        <div class="progress-bar progress-bar-striped ${ar[i - 1] === 100
					? 'bg-success'
					: 'bg-info'}" role="progressbar" style="width: ${ar[i - 1]}%" aria-valuenow="${ar[
					i - 1
				]}" aria-valuemin="0" aria-valuemax="100"></div>
      </div>`
			},
			v[1]
		];
	});

	console.log(ar);
	console.log(data_roadmap);

	return (
		<Card className="my-5">
			<Card.Header>
				Roadmap
				{auth.isAdmin() && (
					<Button size="sm" onClick={save} className="ml-3">
						SAVE
					</Button>
				)}
			</Card.Header>
			<Card.Body>
				<OrgChartDiv>
					<Chart
						width={'100%'}
						height={550}
						chartType="OrgChart"
						loader={<div>Loading Chart</div>}
						data={data_roadmap}
						options={{
							allowHtml: true
						}}
						rootProps={{ 'data-testid': '1' }}
						chartEvents={[
							{
								eventName: 'ready',
								callback: ({ chartWrapper, google }) => {
									if (isEventSet) return;
									setIsEventSet(true);
									const chart = chartWrapper.getChart();
									google.visualization.events.addListener(chart, 'select', function() {
										if (chart.getSelection().length > 0) toggle(chart.getSelection()[0].row);
										chart.setSelection([]);
									});
								}
							}
						]}
					/>
				</OrgChartDiv>
			</Card.Body>
		</Card>
	);
}
