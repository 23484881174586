import React, { useEffect, useState, Fragment } from 'react';
import { Breadcrumb, Button, ButtonGroup, Card, Container, Form, ListGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styled from 'styled-components';
import MyMasonry from '../../../components2/masonry/my-masonry';
import { db } from '../../../utils/DbService';
import toObject from 'form-to-object';
import { useRef } from 'react';

export default function AdminCourses() {
	return (
		<Container className="my-3">
			<Breadcrumb>
				<LinkContainer to="/admin/index">
					<Breadcrumb.Item>Admin Home</Breadcrumb.Item>
				</LinkContainer>
				<Breadcrumb.Item active>Courses</Breadcrumb.Item>
			</Breadcrumb>

			<List />
		</Container>
	);
}

const FancyForm = styled(Form)`
  input:disabled, textarea:disabled {
    border: none;
    background: none;
	}
	
	label {
		font-weight: bold;
		font-size: 0.9em;
		margin-right: 5px;
	}

	.list-group-item {
		display: flex;
		align-items: center;
	}
`;

function List() {
	let [ courses, setCourses ] = useState([]);

	useEffect(() => {
		db.fetch(`admin/courses`).then(setCourses);
	}, []);

	return (
		<MyMasonry
			breakpointCols={{
				default: 4,
				1100: 4,
				700: 2,
				500: 2
			}}
		>
			{courses.map((c) => <Course key={c.id} course={c} setCourses={setCourses} />)}
		</MyMasonry>
	);
}

function Course({ course, setCourses }) {
	let [ loading, setLoading ] = useState(false);
	let [ editMode, setEditMode ] = useState(false);
	const formRef = useRef(undefined);

	const c = course;
	const onSave = (ev) => {
		if (ev) ev.preventDefault();
		setLoading(true);

		const formData = toObject(formRef.current);

		db.fetch(`admin/courses/${course.id}`, { method: 'PUT', body: JSON.stringify(formData) }).then(() => {
			setCourses((prev) =>
				prev.map((c) => (c.id === course.id ? { ...c, ...formData, updated_at: new Date().toString() } : c))
			);
			setLoading(false);
			setEditMode(false);
		});
	};
	const onClose = (ev) => {
		setEditMode(false);
		formRef.current.reset();
	};

	return (
		<Card>
			<FancyForm
				style={{
					opacity: loading ? 0.2 : 1
				}}
				onSubmit={onSave}
				ref={formRef}
			>
				<Card.Img src={c.img || 'https://via.placeholder.com/350x150'} style={{ width: `100%` }} />
				<ListGroup variant="flush">
					<ListGroup.Item>
						<Form.Label>img</Form.Label>
						<Form.Control name="img" placeholder="img url" defaultValue={c.img} disabled={!editMode} />
					</ListGroup.Item>
					<ListGroup.Item>
						<Form.Label>code</Form.Label>
						<Form.Control name="code" placeholder="code" defaultValue={c.code} disabled={!editMode} />
					</ListGroup.Item>
					<ListGroup.Item>
						<Form.Label>name</Form.Label>
						<Form.Control name="name" placeholder="name" defaultValue={c.name} disabled={!editMode} />
					</ListGroup.Item>
					<ListGroup.Item>
						<Form.Control name="desc" defaultValue={c.desc} disabled={!editMode} as="textarea" rows={5} />
					</ListGroup.Item>
				</ListGroup>
				<Card.Footer>
					{editMode ? (
						<ButtonGroup>
							<Button variant="success" onClick={onSave}>
								Save
							</Button>
							<Button variant="danger" onClick={onClose}>
								Discard
							</Button>
						</ButtonGroup>
					) : (
						<Fragment>
							<Button onClick={() => setEditMode(true)}>Edit</Button>
							<LinkContainer to={`/member/my-courses/${c.code}`}>
								<Button variant="link">View</Button>
							</LinkContainer>
						</Fragment>
					)}
				</Card.Footer>
			</FancyForm>
		</Card>
	);
}
