import React from 'react';

import { sortableElement, sortableContainer } from 'react-sortable-hoc';

const SortableElement = sortableElement(({ children, value }) => {
	return <div>{children(value)}</div>;
});

const MySortableContainer = sortableContainer(
	({ data, children }) => {
		return (
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}
			>
				{data.map((v, i) => {
					return (
						<SortableElement index={i} value={v} key={`${i}`}>
							{children}
						</SortableElement>
					);
				})}
			</div>
		);
	},
	{ axis: 'xy' }
);

export { MySortableContainer };
