import React from 'react';
import Masonry from 'react-masonry-css';
import './my-masonry.scss';

const defaultBreakPointCols = {
	default: 4,
	1100: 3,
	700: 2,
	500: 1
};

export default function MyMasonry({ children, breakpointCols = defaultBreakPointCols }) {
	return (
		<Masonry breakpointCols={breakpointCols} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
			{children}
		</Masonry>
	);
}
