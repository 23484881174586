import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import './App2.scss';
import Footer from './components2/navigation/Footer';
import { UserContext } from './context/Contexts';
import AdminIndex from './pages/admin';
import MemberIndex from './pages/member/member-index';
import Login from './pages/public/forms/login';
import { auth } from './utils/DbService';
import TopNav from './components2/navigation/top-nav';
import PrivateRoute from './components2/router/PrivateRoute';

const Wrapper = styled.div`min-height: 100vh;`;

export default function App() {
	return (
		<Router>
			<AppInner />
		</Router>
	);
}

function AppInner() {
	let [ username, setUsername ] = useState(auth.loggedIn() ? auth.getUsername() : undefined);

	const location = useLocation();
	const history = useHistory();
	const [ lang, setLang ] = useState(window.localStorage.getItem('lang') || 'en');

	useEffect(
		() => {
			window.scrollTo(0, 0);
		},
		[ location.pathname ]
	);

	useEffect(
		() => {
			window.localStorage.setItem('lang', lang);
		},
		[ lang ]
	);

	const confirmLogin = (username, from) => {
		setUsername(username);
		if (username) {
			if (from) history.push(from);
			else history.push('/member');
		}
	};

	return (
		<UserContext.Provider value={{ username, confirmLogin, lang, setLang }}>
			<Wrapper className="d-flex flex-column">
				<TopNav />

				<div className="d-flex flex-column flex-grow-1">
					<Switch>
						{/* <PrivateRoute path="/student" component={StudentIndex} />
						<PrivateRoute path="/admin" component={AdminIndex} />
						<Route exact path="/parentlogin/:from?" component={LoginForm2Parent} />

						<Route exact path="/legal/:type" component={Legal} />
						<Route exact path="/schedu
						le" component={PublicSchedule} />
						<Route exact path="/korean" component={Korean} />
						<Route path="/pricing" component={Pricing} />
						<PrivateRoute path="/parent/dashboard/:username?" component={Dashboard} parent /> */}

						{/* ------------------------------------------------------- */}

						<Route exact path="/login/:from?" component={Login} />
						<Route exact path="/parentlogin" component={Login} />
						<PrivateRoute path="/member" component={MemberIndex} />
						<PrivateRoute path="/admin" component={AdminIndex} admin />
						<Route exact path="/">
							<Redirect to="/member" />
						</Route>
					</Switch>
				</div>
				{location.pathname !== '/' && <Footer />}
			</Wrapper>
		</UserContext.Provider>
	);
}
