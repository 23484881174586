import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import { db } from '../../../utils/DbService';

export default function AdminStudents() {
	const [ students, setStudents ] = useState([]);
	const [ activeOnly, setActiveOnly ] = useState(false);

	useEffect(() => {
		db.fetch(`admin/users`).then(setStudents);
	}, []);

	return (
		<Container className="my-3">
			<Breadcrumb>
				<LinkContainer to="/admin/index">
					<Breadcrumb.Item>Admin Home</Breadcrumb.Item>
				</LinkContainer>
				<Breadcrumb.Item active>Students</Breadcrumb.Item>
			</Breadcrumb>

			<div className="my-3 d-flex justify-content-between align-items-center">
				<h3 className="text-primary">Roster</h3>
				<div className="d-flex align-items-center">
					<Toggle id="active-toggle" defaultChecked={activeOnly} onChange={() => setActiveOnly((v) => !v)} />
				</div>
			</div>

			<div className="d-flex flex-wrap">
				{students.filter((s) => activeOnly || s.is_active).map((s) => (
					<Card
						as={Link}
						to={`/admin/student/${s.id}`}
						key={s.id}
						className="m-1 p-2"
						bg={s.is_active ? 'info' : 'light'}
						text={s.is_active ? 'white' : 'default'}
						style={{textDecoration: 'none'}}
					>
						<span role="img" aria-label="dracula">
							{s.is_active ? '😀' : '👻'}
						</span>
						{s.name}
					</Card>
				))}
			</div>
		</Container>
	);
}
