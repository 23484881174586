import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FullScreenLoading } from '../../../components2/loading/Loading';
import MyMasonry from '../../../components2/masonry/my-masonry';
import { db } from '../../../utils/DbService';

export default function MyCoursesList() {
	const { pathname } = useLocation();

	let [ userInfo, setUserInfo ] = useState(undefined);

	useEffect(() => {
		db.fetch(`users/me`).then(setUserInfo);
	}, []);

	if (!userInfo) return <FullScreenLoading txt="Loading student data" />;
	let sortedCourses = [ ...userInfo.courses ].sort((a, b) => (a.code < b.code ? -1 : 1));

	return (
		<Container className="mt-3 mb-3">
			<Breadcrumb>
				<LinkContainer to="/member">
					<Breadcrumb.Item>Member Home</Breadcrumb.Item>
				</LinkContainer>
				<Breadcrumb.Item active>Courses</Breadcrumb.Item>
			</Breadcrumb>

			<MyMasonry>
				{sortedCourses.map((c, i) => {
					return (
						<Card key={c.id} className="text-center">
							<Card.Img src={c.img} />
							<Card.Body>
								<Link to={`${pathname}/${c.code}`}>{c.code.toUpperCase()}</Link>
							</Card.Body>
							{/* <ProgressBar now={0} animated className="rounded-0" /> */}
						</Card>
					);
				})}
			</MyMasonry>
		</Container>
	);
}
