import { MarkdownPreview } from 'react-marked-markdown/dist';
import styled from 'styled-components';

const MarkdownPreviewStyled = styled(MarkdownPreview)`
h1,
h2,
h3,
h4,
h5 {
  margin: 30px 0 20px 0;
}

h1 {
  color: var(--primary);
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  color: var(--secondary);
  font-size: 1.5em;
}
li {
  margin: 5px 0;
}
hr {
  margin: 50px 0;
}
img {
  max-width: 80%;
  margin: 10px auto;
}
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

table th,
table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eceeef;
}

table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}

table tbody + tbody {
  border-top: 2px solid #eceeef;
}

table table {
  background-color: #fff;
}

table {
  border: 1px solid #eceeef;
}

table th,
table td {
  border: 1px solid #eceeef;
}

table thead th,
table thead td {
  border-bottom-width: 2px;
}

table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

table {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table {
  border: 0;
}
`;

export default MarkdownPreviewStyled;
