import 'easymde/dist/easymde.min.css';
import React, { Fragment, useState } from 'react';
import { Breadcrumb, Button, ButtonGroup, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router-dom';
import SimpleMDE from 'react-simplemde-editor';
import { auth, db } from '../../../../utils/DbService';
import MarkdownPreviewStyled from './viewer-div';
import { FullScreenLoading } from '../../../../components2/loading/Loading';


function MarkdownEditor({ markdown, onUpdate, close }) {
	let [ loading, setLoading ] = useState(false);
	let [ txt, setTxt ] = useState(markdown);

	return (
		<Fragment>
			<SimpleMDE
				onChange={setTxt}
				value={txt}
				options={{ autofocus: true, spellChecker: false, breaks: true, sanitize: false }}
			/>

			<ButtonGroup>
				<Button
					disabled={loading}
					variant="outline-success"
					onClick={() => {
						setLoading(true);
						onUpdate(txt);
					}}
				>
					SAVE
				</Button>

				<Button variant="outline-danger" disabled={loading} onClick={close}>
					Cancel
				</Button>
			</ButtonGroup>
		</Fragment>
	);
}

function MarkdownViewer() {
	const { chapterId, pcode } = useParams();

	let [ markdown, setMarkdown ] = useState(undefined);
	let [ editMode, setEditMode ] = useState(false);
	let [ chaptername, setChaptername ] = useState('...');

	async function onUpdate(txt) {
		setMarkdown(undefined);
		await db.fetch(`admin/courses/markdown/chapter_id/${chapterId}`, {
			method: 'PUT',
			body: JSON.stringify({
				markdown: txt
			})
		});
		setMarkdown(txt);
		setEditMode(false);
	}

	async function updateChaptername() {
		let name = window.prompt('Enter new name:', chaptername);
		if (!name) return;
		await db.fetch(`admin/courses/chapters/${chapterId}`, {
			method: 'PUT',
			body: JSON.stringify({ name })
		});
		setChaptername(name);
	}

	React.useEffect(
		() => {
			db.fetch(`courses/markdown/chapter_id/${chapterId}`).then((chapter) => {
				if (!chapter.markdown) setMarkdown('No Content');
				else setMarkdown(chapter.markdown);
			});
			db.fetch(`courses/chapters/${chapterId}`).then((chapter) => setChaptername(chapter.name));
		},
		[ chapterId ]
	);

	if (typeof markdown === 'undefined') return <FullScreenLoading txt="Loading Chapter Material" />;

	return (
		<Container className="my-3">
			<Breadcrumb>
				<LinkContainer to="/member">
					<Breadcrumb.Item>Member Home</Breadcrumb.Item>
				</LinkContainer>
				<LinkContainer to="/member/my-courses">
					<Breadcrumb.Item>Courses</Breadcrumb.Item>
				</LinkContainer>
				<LinkContainer to={`/member/my-courses/${pcode}`}>
					<Breadcrumb.Item>{pcode}</Breadcrumb.Item>
				</LinkContainer>
				<Breadcrumb.Item active>
					{chaptername}
					{auth.isAdmin() && (
						<i
							className="fas fa-pen fa-xs ml-1 text-primary"
							style={{ cursor: 'pointer' }}
							onClick={updateChaptername}
						/>
					)}
				</Breadcrumb.Item>
			</Breadcrumb>

			{auth.isAdmin() &&
			!editMode && (
				<div className="d-flex justify-content-end">
					<Button size="sm" onClick={() => setEditMode(!editMode)}>
						Edit
					</Button>
				</div>
			)}
			{editMode ? (
				<MarkdownEditor markdown={markdown} onUpdate={onUpdate} close={() => setEditMode(false)} />
			) : (
				<MarkdownPreviewStyled
					value={markdown}
					markedOptions={{
						sanitize: false,
						breaks: true
					}}
				/>
			)}
		</Container>
	);
}


export default MarkdownViewer;
