import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { FullScreenLoading } from '../../../components2/loading/Loading';
import { db, auth } from '../../../utils/DbService';
import toObj from 'form-to-object';

export default function MemberProfile() {
	const [ student, setStudent ] = useState(true);

	useEffect(() => {
		db.fetch(`users/me`).then(setStudent);
	}, []);

	if (!student) return <FullScreenLoading txt="Loading..." />;

	return (
		<Container className="my-3">
			<PasswordForm />
		</Container>
	);
}

function PasswordForm() {
	const [ touched, setTouched ] = useState(false);
	const [ formErrors, setFormErrors ] = useState({});
	const [ loading, setLoading ] = useState(false);

	const onSubmit = async (event) => {
		event.preventDefault();
		setTouched(true);
		setFormErrors({});

    if (!event.target.checkValidity()) return;
    setTouched(false);

		const formData = toObj(event.target);
		if (formData.password2 !== formData.password3) {
			setFormErrors({ password3: 'password do not match' });
			return;
		}

		// perform action
		if (loading) return;
    setLoading(true);
    
    const api_url = auth.getParent() ? "users/changePassword2" : "users/changePassword";

		try {
			await db.fetch(api_url, {
				method: 'POST',
				body: JSON.stringify(formData)
			});
			alert('password successfully changed');
		} catch (err) {
			if (err.response.status === 400) setFormErrors({ password: 'Invalid Password' });
		}
		setLoading(false);
	};

	return (
		<Form noValidate validated={touched} onSubmit={onSubmit}>
			<Form.Group>
				<Form.Label>Current Password</Form.Label>
				<Form.Control type="password" name="password" isInvalid={!!formErrors.password} />
				<Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>New Password (5~20 characters)</Form.Label>
				<Form.Control type="password" name="password2" required minLength={5} maxLength={20} />{' '}
			</Form.Group>
			<Form.Group>
				<Form.Label>New Password Again</Form.Label>
				<Form.Control
					type="password"
					name="password3"
					required
					minLength={5}
					maxLength={20}
					isInvalid={!!formErrors.password3}
				/>
				<Form.Control.Feedback type="invalid">{formErrors.password3}</Form.Control.Feedback>
			</Form.Group>

			<Button type="submit" disabled={loading}>
				Change Password
			</Button>
		</Form>
	);
}
