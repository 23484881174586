import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Inquiry from './main/inquiry';
import AdminMain from './main/main';
import AdminStudents from './roster/students';
import AdminStudent from './roster/student/index';
import AdminCourses from './courses/courses';
import AdminQuizTags from './quiz/quiz-tags';
import AdminQuizList from './quiz/list';

export default function AdminIndex() {
	return (
		<Switch>
			<Route path="/admin/courses" component={AdminCourses} />
			<Route path="/admin/students" component={AdminStudents} />
			<Route path="/admin/student/:studentId" component={AdminStudent} />
			<Route path="/admin/inquiry" component={Inquiry} />
			<Route path="/admin/quiz-tags" component={AdminQuizTags} />
			<Route path="/admin/quiz/:tagId" component={AdminQuizList} />
			<Route path="/admin/index" component={AdminMain} />
		</Switch>
	);
}
