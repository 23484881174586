import { format } from 'date-fns';
import formToObj from 'form-to-object';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Alert, Badge, Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { db } from '../../../../utils/DbService';

const Reports = ({ student }) => {
	const [ reports, setReports ] = useState([]);
	const [ report, setReport ] = useState(undefined); // selected report for editing/viewing.

	useEffect(
		() => {
			db.fetch(`admin/reports/uid/${student.id}`).then(setReports);
		},
		[ student ]
	);

	return (
		<Fragment>
			<h3 className="text-primary mb-3">Reports</h3>

			<Table>
				<thead>
					<tr>
						<th>Date</th>
						<th colSpan={4}>Instructor</th>
					</tr>
				</thead>
				<tbody>
					{reports.map((r) => (
						<tr key={r.id}>
							<td>{format(new Date(r.report_date), 'yyyy-MM-dd')}</td>
							<td>{r.createdBy.name}</td>
							<td>{r.archived === 1 && <Badge variant="dark">Archived</Badge>}</td>
							<td>{r.text && r.text.substring(0, 30)}...</td>
							<td>
								<span
									role="img"
									aria-label="pencil"
									style={{ cursor: `pointer` }}
									onClick={() => setReport(r)}
								>
									✏️
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</Table>

			<Button onClick={() => setReport({})} className="m-1" variant="primary">
				<i className="fas fa-plus-circle" /> CREATE A NEW REP0RT
			</Button>

			<ReportForm
				showForm={!!report}
				hideForm={() => setReport(undefined)}
				student={student}
				setReports={setReports}
				report={report}
				key={report}
			/>
		</Fragment>
	);
};
const ReportForm = ({ showForm, hideForm, student, report, setReports }) => {
	const [ loading, setLoading ] = useState(false);
	const [ msg, setMsg ] = useState(undefined);
	const ref = useRef(null);

	useEffect(
		() => {
			if (msg) {
				const to = setTimeout(() => {
					setMsg(false);
				}, 2000);
				return () => clearTimeout(to);
			}
		},
		[ msg ]
	);

	async function createReport(formData) {
		return db
			.fetch(`admin/reports`, { method: 'POST', body: JSON.stringify(formData) })
			.then((data) =>
				setReports((prev) => [ { id: data.id, ...formData, createdBy: { name: 'Just Added' } }, ...prev ])
			);
	}

	async function updateReport(formData) {
		return db
			.fetch(`admin/reports/${report.id}`, { method: 'PUT', body: JSON.stringify(formData) })
			.then(() =>
				setReports((prev) => prev.map((rec) => (rec.id === report.id ? { ...rec, ...formData } : rec)))
			);
	}

	const onSubmit = (ev) => {
		if (ev) ev.preventDefault();
		setLoading(true);

		let obj = formToObj(ref.current);
		obj.user_id = obj.user_id || student.id;
		obj.archived = obj.archived === 'on' ? 1 : 0;

		if (!report.id)
			createReport(obj).then(() => {
				ref.current.reset();
				hideForm();
			});
		else
			updateReport(obj).then(() => {
				setMsg('SUCCESS');
				setLoading(false);
			});
	};

	if (!report) return <Fragment />;

	return (
		<Modal show={showForm} onHide={hideForm} className="flex-column">
			<Modal.Header closeButton className="bg-primary text-white">
				<Modal.Title>
					<span role="img" aria-label="pencil">
						✏️
					</span>
					{report && report['id'] ? 'Edit Report' : 'Create a new report'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{msg && <Alert variant="success">{msg}</Alert>}
				<Form onSubmit={onSubmit} ref={ref}>
					<Form.Group>
						<Form.Label>Date</Form.Label>
						<Form.Control
							type="date"
							name="report_date"
							defaultValue={
								report['report_date'] ? (
									format(new Date(report.report_date), 'yyyy-MM-dd')
								) : (
									format(new Date(), 'yyyy-MM-dd')
								)
							}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Report</Form.Label>
						<Form.Control type="text" name="text" as="textarea" rows="10" defaultValue={report['text']} />
					</Form.Group>
					<Form.Group className="d-flex align-items-center justify-content-end">
						<Form.Label className="my-0 mr-2">Archived</Form.Label>
						<Toggle id="active-toggle" defaultChecked={report['archived'] === 1} name="archived" />
					</Form.Group>
				</Form>
				<Alert variant="danger">
					<i className="fas fa-exclamation-triangle" /> This is a public record shared to parents
				</Alert>
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={loading} onClick={onSubmit}>
					{loading ? (
						<Fragment>
							<Spinner animation="grow" /> Loading...
						</Fragment>
					) : (
						`Submit`
					)}
				</Button>
				<Button disabled={loading} variant="secondary" onClick={hideForm}>
					Discard
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default Reports;
