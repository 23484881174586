import toObj from 'form-to-object';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { db } from '../../../../../utils/DbService';

export default function AlgoIndexEdit({ show, onClose, student, indexes, setIndexes }) {
	
	return (
		<Modal show={show} onHide={onClose} size="lg">
			<Modal.Header closeButton>Edit</Modal.Header>
			<Modal.Body>
				{indexes.map((idx, i) => <AlgoForm idx={idx} key={i} i={i} student={student} setIndexes={setIndexes} />)}
			</Modal.Body>
		</Modal>
	);
}

function AlgoForm({ idx, student, setIndexes, i }) {
	const [ loading, setLoading ] = useState(false);

	const { yyyy, mm, level } = idx;

	const onSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		const formData = {
			user_id: student.id,
			yyyy,
			mm,
			...toObj(event.target)
		};
		await db.fetch(`admin/algoindex`, {
			method: 'POST',
			body: JSON.stringify(formData)
		});
		setIndexes((prev) => prev.map((v, ii) => (ii === i ? formData : v)));
		setLoading(false);
	};

	return (
		<Form onSubmit={onSubmit}>
			<Form.Group
				style={{
					display: 'grid',
					gridTemplateColumns: 'auto auto auto'
				}}
			>
				<Form.Label>
					{yyyy}-{mm}
				</Form.Label>
				<Form.Control name="level" type="number" defaultValue={level} />
				<Button type="submit" disabled={loading}>
					{loading ? 'loading....' : 'Update'}
				</Button>
			</Form.Group>
		</Form>
	);
}
