import React from 'react';
import { Card } from 'react-bootstrap';
import Chart from 'react-google-charts';

export default function AlgoIndex({ chartData }) {
	return (
		<Card className="mb-3">
			<Card.Header>BTree Algo-Index</Card.Header>
			<Card.Body className="d-flex align-items-center justify-content-center">
				<Chart
					width={'100%'}
					height={'400px'}
					chartType="Line"
					loader={<div>Loading Chart</div>}
					data={chartData}
					rootProps={{ 'data-testid': '1' }}
					options={{
						legend: {
							position: 'bottom'
						}
					}}
					style={{
						fontSize: '0.9em'
					}}
				/>
			</Card.Body>
		</Card>
	);
}
