import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const Footer = ({ bg = 'bg-dark' }) => (
	<Container className={`${bg} pt-5 pb-5`} color="primary" fluid>
		<Container>
			<div className="mb-3">
				<a
					className="mr-5 font-weight-bold"
					href="https://discord.gg"
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fab fa-discord" /> Discord
				</a>

				{/* <a
					className="mr-5 font-weight-bold"
					href="https://github.com/BTREE-ACADEMY"
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fab fa-github" /> Github
				</a>

				<a
					className="mr-5 font-weight-bold"
					href="https://github.com/BTREE-ACADEMY"
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fab fa-facebook" /> Facebook
				</a>

				<a
					className="mr-5 font-weight-bold"
					href="https://github.com/BTREE-ACADEMY"
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fab fa-instagram" /> Instagram
				</a> */}
			</div>
			<div>
				<div>810 Roosevelt, Irvine, CA 92620</div>
				<div>949-288-3535</div>
				<div>btreecode@gmail.com</div>
				<small className="mt-5">&copy; 2020 Btree Education, Inc. All rights reserved.</small>
			</div>
		</Container>
	</Container>
);

export default withRouter(Footer);
