import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { auth } from '../../utils/DbService';

const PrivateRoute = ({ component: Component, admin = false, ...rest }) => {
	const location = useLocation();
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!auth.loggedIn()) {
					return (
						<Redirect
							to={{
								pathname: '/login',
								search: '?from=' + location.pathname
							}}
						/>
					);
				}

				if (!admin || auth.isAdmin()) return <Component {...props} />;
				else return <Redirect to={'/'} />;
			}}
		/>
	);
};

export default PrivateRoute;
