import React from 'react';
import { Container } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

const colorAnimation = keyframes`
	from, to {
		color: var(--primary);
	}
	50% {
		color: var(--dark);
	}
`;
const blinkAnimation = keyframes`
  from,to {
    color: var(--primary);
  }
  50% {
    opacity: 0.1;
  }
`;
const Color = styled.div`animation: 1s ${colorAnimation} infinite;`;
const Blink = styled.div`animation: 1s ${blinkAnimation} infinite;`;

const Loading = () => (
	<div className="Loading">
		<i className="fa fa-spin fa-cog fa-3x fa-fw" />
		Loading...
	</div>
);

export default Loading;

export const LoadingIcon = () => <i className="fa fa-spin fa-circle-o-notch fa-fw fa-2x" />;

export const FullScreenLoading = ({ txt, style }) => (
	<Container className="p-5 text-center" style={style} fluid>
		<Color>
			<i className="fa fa-spin fa-cog fa-3x fa-fw" />
		</Color>
		<Blink>{txt || 'Loading...'}</Blink>
	</Container>
);
