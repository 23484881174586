import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { auth, db } from '../../../utils/DbService';
import { Alert } from 'react-bootstrap';

export default function DashboardTimeline({ student }) {
	let [ events, setEvents ] = useState([]);

	const consolidateEvents = (events, type, sortDate) => {
		setEvents((prev) => {
			const cur = [ ...prev, ...events.map((t) => ({ ...t, type, date: new Date(t[sortDate]) })) ];
			cur.sort((a, b) => (a.date > b.date ? -1 : 1));
			return cur;
		});
	};

	useEffect(() => {
		if (!student) {
			db.fetch(`classrecords/me?numMonth=48`).then((data) => consolidateEvents(data, 'class', 'date'));
			db.fetch(`codequizrecords/me?numMonths=48`).then((data) => consolidateEvents(data, 'quiz', 'created_at'));
			db.fetch(`reports/me`).then((data) => consolidateEvents(data, 'report', 'report_date'));
		} else if (auth.isAdmin()) {
			// admin
			db
				.fetch(`admin/classrecords/uid/${student.id}?numMonths=48`)
				.then((data) => consolidateEvents(data, 'class', 'date'));
			db
				.fetch(`admin/codequizrecords?user_id=${student.id}&numMonths=48`)
				.then((data) => consolidateEvents(data, 'quiz', 'created_at'));
			db.fetch(`admin/reports/uid/${student.id}`).then((data) => consolidateEvents(data, 'report', 'report_date'));
		}
	}, [student]);

	return (
		<Timeline>
			{events.map((ev, i) => {
				switch (ev.type) {
					case 'class':
						return (
							<TimelineEvent
								style={{ marginTop: '15px' }}
								key={ev.type + ev.id}
								cardHeaderStyle={{ color: 'gold' }}
								title={ev.is_present ? `Class attended` : `Missed class`}
								titleStyle={{ color: ev.is_present ? 'var(--success)' : 'var(--danger)', fontWeight: 'bold' }}
								subtitle={
									<Fragment>
										<span>Homework: </span>
										{ev.did_homework === 2 && <i className="text-success fas fa-check" />}
										{ev.did_homework >= 0 && <i className="text-success fas fa-check" />}
										{ev.did_homework === -1 && <i className="text-danger fas fa-times" />}

										<span className="ml-2">Quiz: </span>
										{ev.quiz_score === 2 && <i className="text-success fas fa-check" />}
										{ev.quiz_score >= 0 && <i className="text-success fas fa-check" />}
										{ev.quiz_score === -1 && <i className="text-danger fas fa-times" />}
									</Fragment>
								}
								subtitleStyle={{
									display: ev.is_present ? 'block' : 'none'
								}}
								createdAt={format(ev.date, 'yyyy-MM-dd')}
								icon={
									ev.is_present === 0 ? (
										<i className="fas fa-calendar-check" />
									) : (
										<i className="fas fa-calendar-times" />
									)
								}
								iconStyle={{ color: ev.is_present ? 'var(--success)' : 'var(--danger)' }}
								bubbleStyle={{ border: `2px solid var(--${ev.is_present ? 'success' : 'danger'})` }}
							>
								{ev.notes}
								{ev.next_homework && (
									<div>
										<strong className="font-weight-bold">Next Homework</strong> {ev.next_homework}
									</div>
								)}
							</TimelineEvent>
						);
					case 'quiz':
						return (
							<TimelineEvent
								style={{ marginTop: '15px' }}
								key={ev.type + ev.id}
								title={
									<Fragment>
										[{ev.level}] {ev.name}
									</Fragment>
								}
								titleStyle={{ color: 'var(--primary)', fontWeight: 'bold' }}
								subtitle={
									<Fragment>
										{ev.pt} / {ev.pt_outof}
									</Fragment>
								}
								createdAt={format(ev.date, 'yyyy-MM-dd hh:mm')}
								icon={<i className="fas fa-code" />}
								iconStyle={{ color: 'var(--primary)' }}
								bubbleStyle={{ border: '2px solid var(--primary)' }}
							/>
						);
					case 'report':
						if (auth.getParent() || auth.isAdmin())
							return (
								<TimelineEvent
									key={ev.type + ev.id}
									style={{ marginTop: '15px' }}
									title="Report"
									titleStyle={{
										color: 'var(--warning)',
										fontWeight: 'bold'
									}}
									createdAt={format(ev.date, 'yyyy-MM-dd hh:mm')}
									icon={<i className="fas fa-envelope" />}
									iconStyle={{ color: 'var(--warning)' }}
									bubbleStyle={{ border: '2px solid var(--warning)' }}
								>
									report by <strong className="text-primary">{ev.createdBy.name}</strong>
									{
										ev.archived === 1 && <Alert variant="dark">ARCHIVED</Alert>
									}
									<p className="mb-0" style={{ whiteSpace: 'break-spaces' }}>
										{ev.text}
									</p>
								</TimelineEvent>
							);
						return <Fragment key={ev.type + ev.id} />;
					default:
						// not supposed to happen
						return <Fragment>?</Fragment>;
				}
			})}
		</Timeline>
	);
}
