import form2Obj from 'form-to-object';
import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumb, Button, ButtonGroup, Card, Container, Form, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styled from 'styled-components';
import { MySortableContainer } from '../../../components2/sortable-container/sortable-container';
import { db } from '../../../utils/DbService';
import { Link } from 'react-router-dom';

export default function AdminQuizTags() {
	return (
		<Container className="my-3">
			<Breadcrumb>
				<LinkContainer to="/admin/index">
					<Breadcrumb.Item>Admin Home</Breadcrumb.Item>
				</LinkContainer>
				<Breadcrumb.Item active>Quizbank</Breadcrumb.Item>
			</Breadcrumb>

			<List />
		</Container>
	);
}

const CardList = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

function List() {
	const [ tags, setTags ] = useState([]);
	const [ tag, setTag ] = useState(undefined);
	const [ dragMode, setDragMode ] = useState(false);

	useEffect(() => {
		db.fetch(`codequizTag`).then((data) => {
			data.sort((a, b) => a.order - b.order);
			setTags(data);
		});
	}, []);

	const addTag = () => {};
	const removeTag = (id) => {
		if (!window.confirm('are you sure?')) return;
		db
			.fetch(`admin/codequizTags/${id}`, {
				method: 'DELETE'
			})
			.then(() => setTags((prev) => prev.filter((t) => t.id !== id)));
	};

	const onSortEnd = ({ newIndex, oldIndex }) => {
		setTags((prev) => {
			let moved = prev[oldIndex];
			let cur = prev.filter((_, i) => i !== oldIndex);
			cur = [ ...cur.slice(0, newIndex), moved, ...cur.slice(newIndex) ];

			for (let i = Math.min(oldIndex, newIndex); i <= Math.max(oldIndex, newIndex); i++) {
				const tag = cur[i];
				db.fetch(`admin/codequizTags/${tag.id}`, {
					method: 'PUT',
					body: JSON.stringify({ order: i })
				});
			}
			return cur;
		});
	};

	return (
		<Fragment>
			<div className="d-flex justify-content-end">
				<a
					href="https://paper.dropbox.com/doc/Level-List--AwjGPId8VxVdXSFReYBC4H0oAQ-Nah2biNPABDZGOeBDn09z"
					target="_blank"
					rel="noopener noreferrer"
					className="mr-5"
				>
					Level Reference
				</a>
				<ButtonGroup>
					<Button
						size="sm"
						variant={dragMode ? 'primary' : 'outline-primary'}
						value={dragMode}
						onClick={() => setDragMode((v) => !v)}
					>
						<i className="fas fa-sort-amount-down" />
					</Button>
					<Button size="sm" variant="outline-primary" onClick={addTag}>
						<i className="fas fa-plus" />
					</Button>
				</ButtonGroup>
			</div>
			{dragMode && (
				<MySortableContainer data={tags} onSortEnd={onSortEnd} axis="xy">
					{(tag) => (
						<Card
							className="m-1 text-center"
							bg="light"
							style={{ width: '100px', height: '100px', cursor: 'pointer', zIndex: 10, lineHeight: '1em' }}
						>
							<Card.Body>
								<small>{tag.name.replace('-', ' ')}</small>
							</Card.Body>
						</Card>
					)}
				</MySortableContainer>
			)}
			{dragMode === false && (
				<CardList>
					{tags.map((tag) => (
						<Card
							key={tag.id}
							className="m-1 text-center"
							style={{ width: '200px', height: '100px', cursor: 'pointer', zIndex: 10 }}
						>
							<Card.Body>
								<div>
									<small style={{ lineHeight: '1em' }}>
										<Link to={`/admin/quiz/${tag.id}`}>{tag.name.replace('-', ' ')}</Link>
									</small>
								</div>
								<Button
									size="sm"
									onClick={() => setTag(tag)}
									style={{
										fontSize: '0.8em'
									}}
									className="p0 ml-1"
								>
									E
								</Button>
								<Button
									size="sm"
									onClick={() => removeTag(tag.id)}
									style={{
										fontSize: '0.8em'
									}}
									className="p0 ml-1"
									variant="danger"
								>
									D
								</Button>
							</Card.Body>
						</Card>
					))}
				</CardList>
			)}

			<QuizTagForm tag={tag} setTag={setTag} setTags={setTags} />
		</Fragment>
	);
}

function QuizTagForm({ tag, setTag, setTags }) {
	const handleClose = () => setTag(undefined);

	const onSubmit = async (event) => {
		event.preventDefault();
		let formData = form2Obj(event.target);
		await db.fetch(`admin/codequizTags/${tag.id}`, { method: 'PUT', body: JSON.stringify(formData) });
		setTags((tags) => tags.map((t) => (t.id === tag.id ? { ...formData, id: tag.id, updated_at: new Date() } : t)));
		setTag(undefined);
	};

	if (!tag) return <Fragment />;

	return (
		<Modal show={!!tag} onHide={handleClose}>
			<Form onSubmit={onSubmit}>
				<Modal.Header className="bg-primary text-white" closeButton>
					<Modal.Title>
						<span role="img" aria-label="pen">
							✍️
						</span>Edit
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Name</Form.Label>
						<Form.Control name="name" defaultValue={tag.name} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Desc</Form.Label>
						<Form.Control name="desc" defaultValue={tag.desc} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" type="submit">
						Save Changes
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
