import formToObject from 'form-to-object';
import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/java';
import 'brace/mode/python';
import 'brace/theme/dracula';
import { Breadcrumb, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router-dom';
import SimpleMDEEditor from 'react-simplemde-editor';
import styled from 'styled-components';
import { db } from '../../../utils/DbService';
import { FullScreenLoading } from '../../../components2/loading/Loading';

export default function AdminQuizList() {
	const [ qs, setQs ] = useState([]);
	const [ tag, setTag ] = useState({ name: 'loading...', desc: 'loading...' });
	const { tagId } = useParams();
	const [ modalQid, setModalQid ] = useState(undefined);

	useEffect(() => {
		db.fetch(`admin/codequizTags/${tagId}`).then(setTag);
		db.fetch(`admin/codequiz?` + new URLSearchParams({ tag: tagId })).then((qs) => setQs(getSorted(qs)));
	}, [tagId]);

	const updateQ = (qid, name, level) => {
		setQs((prev) => {
			let cur = prev.map((qq) => (qq.id === qid ? { ...qq, name, level } : qq));
			return getSorted(cur);
		});
	};

	const getSorted = (qs) => {
		let sorted = [ ...qs ];
		sorted.sort((a, b) => a.level - b.level);
		return sorted;
	};

	const onAdd = () => {
		const name = window.prompt('Name?', 'default');
		if (name) {
			let newQ = {
				name,
				level: 0,
				text: ''
			};

			db
				.fetch(`admin/codequiz`, {
					method: 'POST',
					body: JSON.stringify({
						name,
						level: 0,
						text: '',
						tag: tagId
					})
				})
				.then((data) => {
					newQ.id = data.id;
					setQs(getSorted([ ...qs, newQ ]));
				});
		}
	};

	return (
		<Container className="my-3">
			<Breadcrumb>
				<LinkContainer to="/admin/index">
					<Breadcrumb.Item>Admin Home</Breadcrumb.Item>
				</LinkContainer>
				<LinkContainer to="/admin/quiz-tags">
					<Breadcrumb.Item>Quiz Home</Breadcrumb.Item>
				</LinkContainer>
				<Breadcrumb.Item active>{tag.name}</Breadcrumb.Item>
			</Breadcrumb>

			<h1>{tag.name}</h1>
			<div className="d-flex justify-content-between">
				<p>{tag.desc}</p>
				<div>
					<Button variant="primary" onClick={onAdd}>
						+ADD
					</Button>
				</div>
			</div>

			<div className="d-flex flex-wrap">
				{qs.map((q) => (
					<Card key={q.id} className="m-1">
						<Card.Body>
							<span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => setModalQid(q.id)}>
								{q.name} [{q.level}]
							</span>
						</Card.Body>
					</Card>
				))}
			</div>

			<EditModal qid={modalQid} handleClose={() => setModalQid(undefined)} updateQ={updateQ} />
		</Container>
	);
}

const BigModal = styled(Modal)`
  .modal-dialog {
    max-width: 90%;
  }
`;

const java_template = `import java.util.*;

class Solution {
    public static void main(String[] args) {
        System.out.println(solve(new int[] { 1, -1, 2, -4, 5 }));
        System.out.println(solve(new int[] { -5, 5, 0, 5, 5 }));
        System.out.println(solve(new int[] { -1 }));
    }

    static int solve(int[] ar) {
        // enter code here
        return 0;
    }

    /* HIDDEN */

    public static void main2(String[] args) {
        int[][] tests = { { 1, -1, 2, -4, 5 }, { -5, 5, 0, 5, 5 }, { 1 }, {}, { -2, -2, -2, -2 },
                { 10, 20, 30, 40, 50 } };

        for (int i = 0; i < tests.length; i++) {
            int[] ar = tests[i];

            try {
                if (solve(ar) != solve2(ar)) {
                    System.out.println(tests.length);
                    System.out.println(i);
                    System.out.println("input: " + Arrays.toString(ar));
                    System.out.println("expected: " + solve2(ar));
                    System.out.println("provided: " + solve(ar));
                    return;
                }
            } catch (Exception e) {
                System.out.println(tests.length);
                System.out.println(i);
                System.out.println("input: " + Arrays.toString(ar));
                System.out.println("expected: " + solve2(ar));
                System.out.println("Runtime Error");
                System.out.println(e.getMessage());
                return;
            }
        }
        System.out.println(tests.length);
        System.out.println(tests.length);
    }

    static int solve2(int[] ar) {
        int cnt = 0;
        for (int val : ar)
            if (val < 0)
                cnt++;
        return cnt;
    }
}
`;

const python_template = `def main():
print(solve([1, 2, 3, 4, 5]))
print(solve([-100, 12, 0]))
print(solve([0]))


def solve(ar):
# enter code here
return 0

# hidden

def solve2(ar):
min = 1000
for val in ar:
		if val < min:
				min = val
return min


def main2():
tests = [
		[1, 2, 3, 4, 5],
		[-100, 12, 0],
		[0],
		[3, 2, 1],
		[10, 10, 10, 10, 10],
		[10, 20, 30, 40, 50],
]
print(len(tests))
cnt = 0
for test in tests:
		try:
				if solve(test) == solve2(test):
						cnt += 1
				else:
						print(cnt)
						print("input: ", test)
						print("expected: ", solve2(test))
						print("provided: ", solve(test))
						return -1
		except:
				print(cnt)
				print("input: ", test)
				print("expected: ", solve2(test))
				print("runtime error")
				return -1

print(len(tests))

main2()`;

function EditModal({ qid, handleClose, updateQ }) {
	let [ q, setQ ] = useState(undefined);
	let [ loading, setLoading ] = useState(false);

	useEffect(
		() => {
			setQ(undefined);
			setLoading(true);

			if (qid && qid > 0) {
				db
					.fetch(`admin/codequiz/${qid}`)
					.then((data) => {
						setQ(data);
					})
					.then(() => setLoading(false));
			}
		},
		[ qid ]
	);

	const onSubmit = (ev) => {
		ev.preventDefault();
		setLoading(true);
		let formData = { ...q, ...formToObject(ev.target) };
		formData = (({ name, level, text, java, python, tests, answers }) => ({
			name,
			level,
			text,
			java,
			python,
			tests,
			answers
		}))(formData);
		delete formData.created_at;
		delete formData.updated_at;

		db
			.fetch(`admin/codequiz/${qid}`, {
				method: 'PUT',
				body: JSON.stringify(formData)
			})
			.then(() => {
				setLoading(false);
				updateQ(qid, formData.name, formData.level);
			});
	};

	return (
		<BigModal show={qid > 0} onHide={handleClose} dialogClassName="w-75">
			<Form onSubmit={onSubmit}>
				<Modal.Header className="bg-primary text-white" closeButton>
					Edit ({qid})
				</Modal.Header>

				{q && (
					<Modal.Body>
						{loading && <FullScreenLoading />}
						<Row>
							<Col>
								<Row>
									<Col>
										<Form.Group>
											<Form.Label>Name</Form.Label>
											<Form.Control name="name" defaultValue={q.name} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>Level</Form.Label>
											<Form.Control name="level" type="number" defaultValue={q.level} />
										</Form.Group>
									</Col>
								</Row>

								<Form.Group>
									<Form.Label>Text</Form.Label>
									<SimpleMDEEditor
										onChange={(text) => setQ((v) => ({ ...v, text }))}
										value={q.text}
										options={{
											autofocus: true,
											spellChecker: false,
											breaks: true,
											sanitize: false,
											status: false
										}}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>
										Java (<span className="text-primary" onClick={() => setQ((v) => ({ ...v, java: java_template }))}>
											use template
										</span>)
									</Form.Label>
									<AceEditor
										mode="java"
										theme="dracula"
										width="100%"
										height="400px"
										fontSize="14px"
										wrapEnabled={true}
										onChange={(java) => setQ((v) => ({ ...v, java }))}
										value={q.java || ''}
										name="java"
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>
										Python (<span
											className="text-primary"
											onClick={() => setQ((v) => ({ ...v, python: python_template }))}
										>
											use template
										</span>)
									</Form.Label>
									<AceEditor
										mode="python"
										theme="dracula"
										width="100%"
										height="400px"
										fontSize="14px"
										wrapEnabled={true}
										onChange={(python) => setQ((v) => ({ ...v, python }))}
										value={q.python || ''}
										name="python"
									/>
								</Form.Group>
								{/* <Form.Group>
									<Form.Label>Tests (separated by new lines)</Form.Label>
									<Form.Control name="tests" as="textarea" rows="10" defaultValue={q.tests} />
								</Form.Group>
								<Form.Group>
									<Form.Label>Answers</Form.Label>
									<Form.Control name="answers" as="textarea" rows="10" defaultValue={q.answers} />
								</Form.Group> */}
							</Col>
						</Row>
					</Modal.Body>
				)}

				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" type="submit" disabled={loading}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Form>
		</BigModal>
	);
}
