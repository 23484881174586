import { subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FullScreenLoading } from '../../../components2/loading/Loading';
import { db } from '../../../utils/DbService';
import AlgoIndex from './algo-index';
import DashboardTimeline from './dashboard-timeline';
import RoadMap from './roadmap';

export default function MemberDashboard() {
	let [ student, setStudent ] = useState(undefined);
	const [ indexes, setIndexes ] = useState([]); // algo index

	useEffect(() => {
		db.fetch(`users/me`).then(setStudent);
		db.fetch(`algoindex/me`).then((data) => {
			const indexes = new Array(6).fill(0).map((_, i) => {
				const offsetDate = subMonths(new Date(), i);
				const foundData = data.find((d) => offsetDate.getFullYear() === d.yyyy && offsetDate.getMonth() + 1 === d.mm);
				return {
					yyyy: offsetDate.getFullYear(),
					mm: offsetDate.getMonth() + 1,
					level: foundData ? foundData.level : 0
				};
			});
			setIndexes(indexes);
		});
	}, []);

	if (!student) return <FullScreenLoading txt="Loading student data..." />;

	const chartData = [
		[ '', student.name, 'USACO Bronze', 'USACO Silver' ],
		...indexes.map((v) => [ new Date(v.yyyy, v.mm), v.level, 30, 50 ])
	];

	return (
		<Container className="mt-3 mb-3">
			<h1 className="text-primary">{student.name}</h1>

			<Row>
				<Col>
					<AlgoIndex chartData={chartData} />
					<RoadMap />
				</Col>
				<Col>
					<h3 className="mt-3">Timeline</h3>
					<DashboardTimeline />
				</Col>
			</Row>
		</Container>
	);
}
