import React from 'react';
import { Container, Breadcrumb } from 'react-bootstrap';
import Inquiry from './inquiry';

export default function AdminMain() {
	return (
		<Container className="my-3">
			<Breadcrumb>
				<Breadcrumb.Item active>Admin Home</Breadcrumb.Item>
			</Breadcrumb>

			<Inquiry />
		</Container>
	);
}
